import  {useState} from 'react'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import HeaderSolution from './component/HeaderSolution';
import Modal from 'react-bootstrap/Modal';
import { Routes, Route } from "react-router-dom";
import Contact from './pages/Contact';
import Home from './pages/Home';
import AboutRepliq from './pages/AboutRepliq';
import Header from './component/Header';
import Footer from './component/Footer';
import WhyRepliq from './pages/WhyRepliq';
import Popup from './pages/Popup';
import Solutions from './pages/Solutions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import BookDemo from './component/BookDemo';

function App() {

  const [blank, setBlank] = useState(false);
//   let creat_url = (str) => {
//   str = str.split(/\s+/).join("-");
//   str = str.toLowerCase();
//   str = "/knowledge/" + str;
//   return str;
//  }


const scrollToTop = () => {
  setBlank(true);
  setTimeout(() => {
  window.scrollTo(0, 0);
  }, 100);
  setTimeout(() => {
  setBlank(false);            
  }, 600);
}

// document.body.classList.add('no-scroll');

// window.onload = () => {
//   console.log('loaded');
//   document.body.classList.remove('no-scroll');
// }


const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

  return (
    <div className="App">

    <Modal className='modal-book-demo' show={show} size='xl' centered={true}  onHide={handleClose} >
        <Modal.Header closeButton></Modal.Header>
        <BookDemo mainheading="Book a Demo with us Today!"  subheading="Take your business to the next level with digital twinning!" />
    </Modal>

      {(blank) ? <div className='blank'> </div> : ""}
        <Header setBlank={setBlank} book_demo={handleShow} />
        <Routes>
          <Route exact path="/" element={<Home scrollToTop={scrollToTop} book_demo={handleShow} />} ></Route>
          <Route exact path="about-repliq" element={<AboutRepliq scrollToTop={scrollToTop}  />} />
          <Route exact path="solutions" element={<Solutions scrollToTop={scrollToTop} />} />
          <Route exact path="why-repliq" element={<WhyRepliq scrollToTop={scrollToTop} />} />
          <Route exact path="contact" element={<Contact scrollToTop={scrollToTop} />} />
          <Route exact path="privacy-policy" element={<PrivacyPolicy scrollToTop={scrollToTop} />} />
          <Route exact path="popup" element={<Popup scrollToTop={scrollToTop} />} />
      </Routes>
      <Footer scrollToTop={scrollToTop} book_demo={handleShow}  />
    </div>
  );
}

export default App;
