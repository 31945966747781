import React from 'react'
import './popup.css';
import banner from '../images/PopupConsumerBehavior.webp';
import { NavLink } from 'react-router-dom';

const PopupConsumerBehavior = ({scrollToTop}) => {
  return (
    <div className='popup-container'>
        <h4>Strengthen safety and security of your spaces with comprehensive virtual replicas</h4>    
        <img src={banner} alt='' />

        <h5>What we do</h5>
        <p>Our digital twin technology enhances safety and security by creating virtual replicas of physical spaces.</p>
        <ul>
            <li>Create detailed 3D representations of facilities and environments</li>
            <li>Integrate real-time data from security systems, sensors, and IoT devices</li>
            <li>Enable continuous monitoring and instant threat detection</li>
            <li>Provide advanced tools for emergency response planning, simulation, and training</li>
        </ul>

        <h5>Advanced Threat Detection</h5>
        <p>Increase security with advanced monitoring and early warning systems.</p>
        <ul>
            <li>Continuously monitor environments with real-time 3D visualization</li>
            <li>Detect potential threats and anomalies promptly</li>
            <li>Receive instant alerts and notifications for immediate action</li>
        </ul>

        <h5>Enhanced Emergency Preparedness</h5>
        <p>Improve readiness and coordination during emergencies with advanced planning tools.</p>
        <ul>
            <li>Visualize emergency scenarios using realistic 3D models</li>
            <li>Develop and practice emergency response plans</li>
            <li>Coordinate efficiently with detailed simulation drills</li>
        </ul>

        <h5>Proactive Cost Management</h5>
        <p>Reduce costs by implementing proactive safety measures and optimizing resource use.</p>
        <ul>
            <li>Prevent incidents and accidents before they happen</li>
            <li>Allocate security resources more effectively</li>
            <li>Lower insurance costs by enhancing overall safety and compliance</li>
        </ul>

        <h5>Improved Safety Compliance</h5>
        <p>Ensure consistent adherence to safety regulations and standards with real-time monitoring and documentation.</p>
        <ul>
            <li>Regularly update and review safety protocols</li>
            <li>Monitor compliance continuously with real-time data</li>
            <li>Generate detailed reports for audits and regulatory requirements</li>
        </ul>

        <h5>Operational Efficiency</h5>
        <p>Streamline and enhance the efficiency of safety and security operations.</p>
        <ul>
            <li>Automate monitoring and threat detection processes</li>
            <li>Simplify security management with integrated systems and centralized control</li>
            <li>Enhance overall operational efficiency through data-driven insights and analytics</li>
        </ul>

        <NavLink className="button" onClick={scrollToTop} to='/contact'> book a demo  </NavLink>
    </div>
  )
}

export default PopupConsumerBehavior
