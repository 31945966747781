import React from 'react'
import './missionvision.css';
import vision from '../images/vision.svg';
import misson from '../images/misson.svg';


const MisionVision = () => {
  return (
    <div className='misson-vision'>
        <div className='container'>
            <div className='mv-boxes'>
                <div className='mv-box'>
                      <div className='img-box'>
                        <img src={vision} alt='' />
                        <h3>Our Vision</h3>
                      </div>
                    <div className='textbox'>
                      <p>Our vision is to be the market leader of digital twinning in India. We aspire to disrupt the status quo by pioneering innovative solutions that set a whole new standard.</p> 
                    </div>
                </div>
                <div className='mv-box mission'>
                    <div className='img-box'>
                        <img src={misson} alt='' />
                        <h3>Our Mission</h3>
                      </div>
                    <div className='textbox'>
                      <p>Our mission is to simplify digital twinning, enabling anyone to create high-quality replicas inexpensively. We aim for rapid, cost-effective twinning, even for large-scale projects, transforming accessibility and efficiency in the process.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MisionVision
