import './popup.css';
import banner from '../images/PopupProductCataloging.webp';
import { NavLink } from 'react-router-dom';

const PopupProductCataloging = ({scrollToTop}) => {
  return (
    <div className='popup-container'>
        <h4>Create immersive 3D product catalogs with future-forward digital twin technology</h4>    
        <img src={banner} alt='' />

        <h5>What we do</h5>
        <p>Our digital twin technology revolutionizes product cataloging by providing a more dynamic and engaging experience for customers while simplifying the management of product information for businesses.</p>
        <ul>
            <li>Develop precise 3D models of products</li>
            <li>Integrate real-time data for dynamic updates</li>
            <li>Enable interactive visualization and customization</li>
            <li>Support e-commerce platforms with advanced product displays</li>
        </ul>

        <h5>Enhanced Product Visualization</h5>
        <p>Showcase products with realistic 3D models.</p>
        <ul>
            <li>Provide detailed and accurate representations</li>
            <li>Allow customers to view products from all angles</li>
            <li>Enable interactive features like zoom and rotation</li>
        </ul>

        <h5>Streamlined Catalog Management</h5>
        <p>Simplify the management of product catalogs.</p>
        <ul>
            <li>Update product information in real-time</li>
            <li>Easily add, remove, or modify products</li>
            <li>Integrate with existing e-commerce platforms</li>
        </ul>

        <h5>Increased Sales</h5>
        <p>Boost sales with compelling product presentations.</p>
        <ul>
            <li>Highlight product features and details effectively</li>
            <li>Improve customer confidence and decision-making</li>
            <li>Increase conversion rates and revenue</li>
        </ul>

        <h5>Improved Customer Engagement</h5>
        <p>Engage customers with immersive product experiences.</p>
        <ul>
            <li>Create interactive and engaging product catalogs</li>
            <li>Enhance online shopping experiences</li>
            <li>Increase customer satisfaction and retention</li>
        </ul>

        <h5>Operational Efficiency</h5>
        <p>Enhance operational efficiency with advanced cataloging tools.</p>
        <ul>
            <li>Reduce the time and cost of product photography</li>
            <li>Automate catalog updates and management</li>
            <li>Improve overall workflow and productivity</li>
        </ul>

        <NavLink className="button" onClick={scrollToTop} to='/contact'>book a demo </NavLink>
    </div>
  )
}

export default PopupProductCataloging
