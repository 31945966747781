import React from 'react'
import { Helmet } from 'react-helmet';
import AboutBanner from '../component/AboutBanner';
import AboutRedefining from '../component/AboutRedefining';
import CoreValues from '../component/CoreValues';
import MisionVision from '../component/MisionVision';

const AboutRepliq = ({scrollToTop}) => {
  return (
    <>
     <Helmet>
      <title>About Repliq</title>
      <meta property="og:title" content="Home" />
      <meta name='og:description' content='Home'/>
      <meta name='keywords' content=''/>
    </Helmet>
        <div>
            <AboutBanner scrollToTop={scrollToTop} />
            <AboutRedefining />
            <CoreValues />
            <MisionVision />
        </div>
    </>
  )
}

export default AboutRepliq
