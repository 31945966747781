import React from 'react'
import './contactbanner.css'
// import bgvideoposter from "../images/contact-banner.webp";
// import bgvideo from "../video/contact-banner.mp4";

const ContactBanner = ({scrollToTop}) => {
  return (
    <div className='contact-banner'>
         {/* <video id="background-video" title='bg-video' playsInline poster={bgvideoposter} loop={true} muted={true} autoPlay={true} >
            <source src={bgvideo} type="video/mp4" />
            <source src={bgvideo} type="video/ogg" />
            Your browser does not support the video tag.
         </video> */}
        <div className='text-box'>
              {/* <div className="scroller">
                <h2>
                  Capture <br/>
                  Visualize   <br/>
                  Transform  
                </h2>
              </div> */}
              <h2>Get in Touch with Us</h2>
             
        </div>
        {/* <div className='d-flex align-center gap-md-4 gap-3'>
              <NavLink to="/#" onClick={scrollToTop}  className="button">Book a Demo</NavLink>
              <NavLink to="/#" onClick={scrollToTop}  className="button">See a Preview</NavLink>
        </div> */}
    </div>
  )
}

export default ContactBanner
