import React from 'react'
import PopupConsumerBehavior from '../component/PopupConsumerBehavior'

const Popup = () => {
  return (
    <div>
        <PopupConsumerBehavior />
    </div>
  )
}

export default Popup
