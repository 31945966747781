import React from 'react'
import './ourproducts.css';
// import { NavLink } from 'react-router-dom';


// import bgvideoposter from "../images/home_banner.webp";
// import bgvideo from "../video/home-banner.mp4";


const OurProducts = (prop) => {
  return (
    <section className={prop.class}>
      {(prop.bgvideo !== "") ?      
        <video id="product-background-video" title='bg-video' playsInline loop={true} muted={true} autoPlay={true} >
          <source src={prop.bgvideo} type="video/mp4" />
          {/* <source src={bgvideo} type="video/ogg" /> */}
          Your browser does not support the video tag.
      </video> : ""
      }

        <h2 className='main-heading'>{prop.heading}</h2>   
        <div className='container'>
            <div className='product-sec'>
                <div className='left'>
                  {/* <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M26.25 14.3758C26.2506 16.1009 25.8312 17.8002 25.0279 19.3268C24.2247 20.8535 23.0618 22.1616 21.6397 23.1382C20.2176 24.1147 18.5792 24.7303 16.8659 24.9318C15.1526 25.1333 13.4161 24.9146 11.8062 24.2945C11.5887 24.2108 11.4812 24.1695 11.3937 24.1495C11.3175 24.1311 11.2396 24.1207 11.1612 24.1183C11.0346 24.1187 10.908 24.1275 10.7825 24.1445L4.38249 24.807C3.76999 24.8695 3.46624 24.9008 3.28624 24.792C3.20861 24.7448 3.14222 24.6811 3.0917 24.6056C3.04118 24.53 3.00775 24.4444 2.99374 24.3545C2.96124 24.1458 3.10624 23.8758 3.39874 23.337L5.44374 19.552C5.61124 19.2395 5.69624 19.0845 5.73374 18.9345C5.77006 18.8024 5.78192 18.6647 5.76874 18.5283C5.75624 18.3733 5.68874 18.1733 5.55374 17.772C5.06309 16.3124 4.8956 14.7635 5.06286 13.2327C5.23013 11.7018 5.72815 10.2257 6.52244 8.90638C7.31674 7.5871 8.38831 6.45625 9.66296 5.59214C10.9376 4.72803 12.3848 4.15132 13.9045 3.90195C15.4241 3.65258 16.9797 3.73652 18.4637 4.14794C19.9476 4.55937 21.3244 5.28844 22.4987 6.28468C23.673 7.28092 24.6166 8.52051 25.2644 9.91759C25.9121 11.3147 26.2484 12.8359 26.25 14.3758Z" stroke="#8E8EEC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg> */}

                    { (prop.title !== "") ? <h3>{prop.title}</h3> : ""}
                    { (prop.subtitle !== "") ? <h4>{prop.subtitle}</h4> :  "" }
                    { (prop.content !== "") ?  <div dangerouslySetInnerHTML={{ __html: prop.content }}></div> : "" }
                
                </div>

                <div className='right'><img src={prop.product} alt='' />
                  {/* <video id="background-video" title='bg-video' playsInline poster={bgvideoposter} loop={true} muted={true} autoPlay={true} >
                      <source src={bgvideo} type="video/mp4" />
                      Your browser does not support the video tag.
                  </video> */}
                  {/* <iframe title='video' src='https://youtu.be/sQ22pm-xvrE?feature=shared'> </iframe> */}
                  {/* <iframe title='video' src={prop.video} ></iframe> */}
                </div>
            </div>
        </div>

        {/* <NavLink to={prop.url} onClick={prop.scrollToTop} className="button" > {prop.btn}</NavLink> */}
             
    </section>
  )
}

export default OurProducts
