import React from 'react'
import { NavLink } from "react-router-dom";
import './difference.css';

import simple from '../images/Simple.webp';
import innovative from '../images/Innovative.webp';
import efficient from '../images/Efficient.webp';
import fast from '../images/Fast.webp';

import simplemb from '../images/Simple-mb.webp';
import innovativemb from '../images/Innovative-mb.webp';
import efficientmb from '../images/Efficient-mb.webp';
import fastmb from '../images/Fast-mb.webp';

import icon from '../images/plus-icon.svg'

import arrowright from '../images/arrow-right.svg';

const Difference = ({scrollToTop}) => {
  return (
    <section className='difference'>
        <div className='container'>
            <div className='row'>
                 <h2 className='main-heading'>The Repliq Difference</h2>   
                 <h4 className='sub-heading'>We offer Digital Twinning solutions that empower businesses to reimagine their surroundings - physical spaces or products, in new and innovative ways.</h4>
            </div>
            <div className='hover-boxes'>
                <div className='hover-box'>
                    <img className='hover-img desktop' src={simple} alt=""  />
                    <img className='hover-img mobile' src={simplemb} alt=""  />
                    <h4 className='title'>Simple</h4>
                    <img className="mobile plus-icon" src={icon} alt='' />
                    <div className='hover-text'>
                        <h4>Make digital twinning easier with our intuitive, seamless platform.</h4>
                        <NavLink to="/why-repliq" onClick={scrollToTop} > Learn More <img src={arrowright} alt="" /></NavLink>
                    </div>
                </div>
                <div className='hover-box'>
                    <img className='hover-img desktop' src={fast} alt=""  />
                    <img className='hover-img mobile' src={fastmb} alt=""  />
                    <h4 className='title'>Fast</h4>
                    <img className="mobile plus-icon" src={icon} alt='' />
                    <div className='hover-text'>
                        <h4>Create digital twins quickly with our scalable solutions, enabling faster decision-making and project implementation.</h4>
                        <NavLink to="/why-repliq" onClick={scrollToTop} > Learn More <img src={arrowright} alt="" /></NavLink>
                    </div>
                </div>

                <div className='hover-box'>
                    <img className='hover-img desktop' src={efficient} alt=""  />
                    <img className='hover-img mobile' src={efficientmb} alt=""  />
                    <h4 className='title'>Efficient</h4>
                    <img className="mobile plus-icon" src={icon} alt='' />
                    <div className='hover-text'>
                        <h4>Maximize productivity through our solutions that allow streamlined operations, reduced costs, and increased efficiency.</h4>
                        <NavLink to="/why-repliq" onClick={scrollToTop} > Learn More <img src={arrowright} alt="" /></NavLink>
                    </div>
                </div>

                <div className='hover-box'>
                    <img className='hover-img desktop' src={innovative} alt=""  />
                    <img className='hover-img mobile' src={innovativemb} alt=""  />
                    <h4 className='title'>Innovative</h4>
                    <img className="mobile plus-icon" src={icon} alt='' />
                    <div className='hover-text'>
                        <h4>Create digital replicas of your space with advanced AI and high-definition 3D visualization.</h4>
                        <NavLink to="/why-repliq" onClick={scrollToTop} > Learn More <img src={arrowright} alt="" /></NavLink>
                    </div>
                </div>
            </div>

            <div className='text-center my-5'>
                {/* <NavLink className="button" to="/why-repliq" onClick={scrollToTop} > Learn More </NavLink> */}
            </div>
        </div>
    </section>

)
}

export default Difference
