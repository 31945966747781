import React, {useState} from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
// import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import brandlogo from "../images/repliq_logo.webp";
import brandlogomb from "../images/repliq-logo-mb.webp";
//import Form from 'react-bootstrap/Form';
import { NavLink } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
// import { useParams } from 'react-router-dom';
import './header.css';


const Header = ({setBlank,book_demo}) => {
    // let { id } = useParams();  
    let expand = 'lg';
    let homeurl = "/";

    // const [show, setShow] = useState(false);    
    // // const [service, setService] = useState('');

    // const showDropdown = (e)=>{
    //     setShow(!show);
    // }
    // const hideDropdown = e => {
    //     setShow(false);
    // }


    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        scrollToTop();
    }



    const scrollToTop = () => {
        setBlank(true);
        setTimeout(() => {
        window.scrollTo(0, 0);
        }, 100);
        setTimeout(() => {
        setBlank(false);            
        }, 600);
    }
    
    return (
    <div className='header'> 
        <Container fluid className='hero'>   
            <Navbar key={expand} bg="transparent"  expand={expand} className="navbar-dark bg-dark">
                <Navbar.Brand href={homeurl}>
                    <img className='desktop' src={brandlogo} alt='' />
                    <img className='mobile' src={brandlogomb} alt='' />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={handleShow} ></Navbar.Toggle>
                <Navbar.Offcanvas show={show} onHide={handleClose}
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                            <NavLink to="/"><img src={brandlogomb}  alt="Logo" /></NavLink>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
              
                        <Nav className="justify-content-end flex-grow-1">
              
                            {/* <NavDropdown 
                                className={`services`}
                                // className={`services ${activeservice} mobile`}
                                // title="Services "
                                title={<NavLink to="/our-offering" onClick={scrollToTop} >Our Offering</NavLink>}
                                // onToggle={() => { window.location.href = '/service'}}
                                id={`offcanvasNavbarDropdown-expand-${expand}`}
                                show={show}
                                // onClick={()=>{toService();}}
                                onMouseEnter={showDropdown} 
                                onMouseLeave={hideDropdown}  
                            >
                                <NavLink to="/#" onClick={scrollToTop}  className="dropdown-item">Sub Menu 1</NavLink>
                                <NavLink to="/#" onClick={scrollToTop}  className="dropdown-item">Sub Menu 2</NavLink>
                                <NavLink to="/#" onClick={scrollToTop}  className="dropdown-item">Sub Menu 3</NavLink>
                               
                            </NavDropdown> */}
                                
                            {/* <div className={`services  nav-item ${service} dropdown desktop`}>
                                <div id="offcanvasNavbarDropdown-expand-md" aria-expanded="true" role="button" className="dropdown-toggle show nav-link" tabIndex="0">
                                 <NavLink to="/our-offering" onClick={scrollToTop} >Our Offering</NavLink>
                                </div>
                                 <div
                                    onMouseEnter={()=>{setService('show')}} 
                                    onMouseLeave={()=>{setService('')}} 
                                    aria-labelledby="offcanvasNavbarDropdown-expand-md" data-bs-popper="static" className={`dropdown-menu  ${service}`}>

                                    <NavLink to="/#" onClick={scrollToTop}  className="dropdown-item">Sub Menu 1</NavLink>
                                    <NavLink to="/#" onClick={scrollToTop}  className="dropdown-item">Sub Menu 2</NavLink>
                                    <NavLink to="/#" onClick={scrollToTop}  className="dropdown-item">Sub Menu 3</NavLink>
                          
                                     </div>
                                </div> */}

                            <NavLink className="nav-link" to="/solutions" onClick={handleClose} >Solutions</NavLink>
                            <NavLink className="nav-link" to="/why-repliq" onClick={handleClose} >Why Repliq</NavLink>
                            <NavLink className="nav-link" to="/about-repliq" onClick={handleClose} >About Repliq</NavLink>
                            <NavLink className="nav-link" to="/contact" onClick={scrollToTop} >Contact Us</NavLink>
                        
                        </Nav>
                        <NavLink className="button manu-btn" to="#" onClick={book_demo} >Book a demo</NavLink>
      
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Navbar>
        </Container> 
    </div>


  )
}

export default Header
