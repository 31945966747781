import React, { useState } from 'react'
import './pprivacybody.css'

const Pprivacybody = ({selectedLanguage}) => {
    const [activeTab, setActiveTab] = useState("tab1");

    return (
        <section className='ppolicybody-section' >

            <div className='top-section'>
                <p>At Repliq, a leader in digital twinning solutions, we take privacy seriously and are committed to safeguarding the personal information of our website visitors, clients, and users of our innovative digital twinning technologies. Our solutions allow users to visualize and reimagine their surroundings—whether physical spaces or products—in new and exciting ways. This privacy policy explains how we collect, use, and protect the information gathered during your visit to our website and through the use of our services.</p>
            </div>

            <div className='tab-section'>
                    <div className=''>

                        {/* <div className="tab">
                            <button className={activeTab === 'tab1' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab1') }} ><span>Use of Information:</span> </button>
                            <button className={activeTab === 'tab2' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab2') }} ><span>Specific uses of information on this Website may include:</span></button>
                            <button className={activeTab === 'tab3' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab3') }} ><span>Legal Disclaimer:</span></button>
                            <button className={activeTab === 'tab4' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab4') }} ><span>Children's Privacy:</span></button>
                            <button className={activeTab === 'tab5' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab5') }} ><span>Links to External Websites:</span></button>
                            <button className={activeTab === 'tab6' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab6') }} ><span>Choice/Opt-Out:</span></button>
                            <button className={activeTab === 'tab7' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab7') }} ><span>Use of Cookies:</span></button>
                            <button className={activeTab === 'tab8' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab8') }} ><span>Via mail:</span></button>
                            <button className={activeTab === 'tab9' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab9') }} ><span>Security:</span></button>
                            <button className={activeTab === 'tab10' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab10') }} ><span>Your Acceptance of These Terms:</span></button>
                        </div>        */}
                        <div className="tab">
                            <button className={activeTab === 'tab1' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab1') }} tooltip="Collection of Information"><span>Collection of Information</span> </button>
                            <button className={activeTab === 'tab2' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab2') }} ><span>Use of Collected Information</span></button>
                            <button className={activeTab === 'tab3' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab3') }} ><span>Sharing Information</span></button>
                            <button className={activeTab === 'tab4' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab4') }} ><span>Web Forms and Agreements</span></button>
                            <button className={activeTab === 'tab5' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab5') }} ><span>Use of Cookies</span></button>
                            <button className={activeTab === 'tab6' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab6') }} ><span>Security</span></button>
                            <button className={activeTab === 'tab7' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab7') }} ><span>Links to Third-Party Websites</span></button>
                            <button className={activeTab === 'tab8' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab8') }} ><span>Legal Disclaimer</span></button>
                            <button className={activeTab === 'tab9' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab9') }} ><span>Children's Privacy</span></button>
                            <button className={activeTab === 'tab10' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab10') }} ><span>Opt-Out Options</span></button>
                            <button className={activeTab === 'tab11' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab11') }} ><span>Google Analytics</span></button>
                            <button className={activeTab === 'tab12' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab12') }} ><span>Contact Us</span></button>
                        </div>       
                    </div>

                    <div className='tabdiv'>
                        <div className={activeTab === "tab1" ? "tabcontent active" : "tabcontent"} >
                          <h2 className='mb-4'>Collection of Information</h2>
                          <p>Repliq does not collect personally identifiable information unless it is provided voluntarily through inquiry forms, sign-up forms, or when entering into agreements with us. However, during your interaction with our website and services, we may automatically gather certain non-personal information. This includes:</p>
                           <ul>
                             <li>Browser type and version</li> 
                             <li>Operating system</li> 
                             <li>IP address</li> 
                             <li>Geographical location (based on IP address)</li>
                             <li>Clickstream behavior</li> 
                           </ul> 
                           <p>This data is collected to improve your experience, optimize website performance, and provide relevant services.</p>
                        </div>

                        <div className={activeTab === "tab2" ? "tabcontent active" : "tabcontent"} >
                            <h2>Use of Collected Information</h2>
                            <p>The information we collect is used to:</p>

                            <ul>
                             <li>Analyze user behavior and interactions with our technologies</li> 
                             <li>Provide a personalized user experience</li> 
                             <li>Improve our platform's performance</li> 
                             <li>Display relevant ads and content</li> 
                             <li>Ensure the security and functionality of our services</li> 
                           </ul> 

                            <p>We do not collect or store personally identifiable information unless explicitly provided by users. No personal information is downloaded onto your device or uploaded from it.</p>

                        </div>

                        <div className={activeTab === "tab3" ? "tabcontent active" : "tabcontent"} >
                            <h2>Sharing Information</h2>
                            <p>We may share non-personally identifiable information with third parties, such as advertisers or partners, to help them understand usage patterns and improve their content or services. This information does not include personal details like your name, address, or email.</p>
                        </div>
                        <div className={activeTab === "tab4" ? "tabcontent active" : "tabcontent"} >
                            <h2>Web Forms and Agreements</h2>
                            <p>When filling out an inquiry form or signing a service agreement with Repliq, you may be asked to provide contact information, such as your name, email, and company details. This information is used solely for providing our services, responding to inquiries, and managing business relationships. Any financial or personal data submitted in agreements or forms will only be used for processing orders and payments, and it may be shared with trusted payment processors.</p>
                        </div>
                        <div className={activeTab === "tab5" ? "tabcontent active" : "tabcontent"} >
                            <h2>Use of Cookies</h2>
                            <p>Cookies are small text files stored on your device to help us track user activity and enhance your experience. Repliq uses cookies to store preferences, maintain session data, and analyze traffic patterns on our website. You can control or disable cookies through your browser settings, but this may affect the functionality of our services.</p>
                        </div>
                        <div className={activeTab === "tab6" ? "tabcontent active" : "tabcontent"} >
                            <h2>Security</h2>
                            <p>Repliq is dedicated to protecting your information; however, no online transmission is completely secure. We take reasonable measures to protect your data from unauthorized access but cannot guarantee absolute security. By using our website, you acknowledge the risks of transmitting data over the internet.</p>
                        </div>
                        <div className={activeTab === "tab7" ? "tabcontent active" : "tabcontent"} >
                            <h2>Links to Third-Party Websites</h2>
                            <p>Repliq may contain links to external websites. We are not responsible for the privacy practices or content of these sites. We encourage users to read the privacy policies of any linked websites before disclosing personal information.</p>
                        </div>
                        <div className={activeTab === "tab8" ? "tabcontent active" : "tabcontent"} >
                            <h2>Legal Disclaimer</h2>
                            <p>Repliq may disclose information in certain circumstances, such as complying with legal obligations, protecting our rights, or investigating potential violations of our terms and conditions. We will not disclose your personal data unless required by law or necessary to protect our interests.</p>
                        </div>
                        <div className={activeTab === "tab9" ? "tabcontent active" : "tabcontent"} >
                            <h2>Children's Privacy</h2>
                            <p>Our services are not designed for or directed at children under the age of 13. We do not knowingly collect personal data from children. If we discover that a child under 13 has provided us with personal information, we will promptly delete it.</p>
                        </div>
                        <div className={activeTab === "tab10" ? "tabcontent active" : "tabcontent"} >
                            <h2>Opt-Out Options</h2>
                            <p>You may opt out of receiving promotional communications from Repliq by following the instructions provided in our emails or by contacting us directly at [contact information]. However, service-related communications necessary for account management cannot be opted out of.</p>
                        </div>
                        <div className={activeTab === "tab11" ? "tabcontent active" : "tabcontent"} >
                            <h2>Google Analytics</h2>
                            <p>We use Google Analytics to collect anonymized data about website usage. This helps us improve our services and provide more relevant content. Users can opt-out of Google Analytics by using the Google Ads Settings or Google Analytics Opt-Out Browser Add-on.</p>
                        </div>
                        <div className={activeTab === "tab12" ? "tabcontent active" : "tabcontent"} >
                            <h2>Contact Us</h2>
                            <p>For any questions or concerns regarding this privacy policy, please contact us at:</p>
                            <p>
                            Via Email: <a href='mailto:privacy@repliq.com'>privacy@repliq.com</a> <br />
                            Via Mail: Repliq, [Address] 
                            </p>
                            <p>By using our website, you agree to the terms of this privacy policy. We may update this policy from time to time, so please review it periodically to stay informed.</p>
                        </div>
                    </div>

            </div>
        </section>

    )
}

export default Pprivacybody