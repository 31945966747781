import React from 'react'
import './aboutbanner.css'
import { NavLink } from "react-router-dom";
// import bgvideoposter from "../images/about_banner.webp";
// import bgvideo from "../video/about-banner.mp4";


const AboutBanner = ({scrollToTop}) => {
  return (
    <div className='about-banner'>
         {/* <video id="background-video" title='bg-video' playsInline poster={bgvideoposter} loop={true} muted={true} autoPlay={true} >
            <source src={bgvideo} type="video/mp4" />
            <source src={bgvideo} type="video/ogg" />
            Your browser does not support the video tag.
         </video> */}
        <div className='text-box'>
              
              {/* <h2>About Repliq</h2>
              <h5>Repliq is a leading provider of innovative digital twinning solutions, revolutionizing the digital twinning industry with simplified and efficient technologies.</h5> */}
              <h2>Revolutionising the digital twinning industry with simplified and efficient technologies.</h2>
        </div>
        <div className='d-flex align-center gap-md-4 gap-3'>
              {/* <NavLink to="/#" onClick={scrollToTop}  className="button">Book a Demo</NavLink> */}
              <NavLink to="/why-repliq" onClick={scrollToTop}  className="button">Why repliq</NavLink>
        </div>
    </div>
  )
}

export default AboutBanner
