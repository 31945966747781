import React from 'react'
import { Helmet } from 'react-helmet';
import HomeBanner from '../component/HomeBanner';
import Difference from '../component/Difference';
import OurProducts from '../component/OurProducts';
// import BookDemo from '../component/BookDemo';
// import OurSolution from '../component/OurSolution';
import OurSolutionGsap from '../component/OurSolutionGsap';

import product from '../images/our-product.webp';

const Home = ({scrollToTop}) => {
  const heading="Our Products";
  const title = "Twinzu - Our Flagship Digital Twinning App";
  const subtitle = "";
  const content = "<p>Meet Twinzu—your gateway to the future of digital twinning. Utilizing advanced 3D rendering and real-time data integration, Twinzu allows you to explore and interact with digital models of physical assets on PCs and smartphones.</p>";
  const video = "https://www.youtube.com/embed/sQ22pm-xvrE";
  const btn = "Book a Demo";
  const url = "/contact";
  


  return (
    <>
     <Helmet>
        <title>Home</title>
        <meta property="og:title" content="Home" />
        <meta name='og:description' content='Home'/>
        <meta name='keywords' content=''/>
     </Helmet>
     <HomeBanner  scrollToTop={scrollToTop} />
     <Difference scrollToTop={scrollToTop} /> 
     {/* <OurSolution /> */}
     <OurSolutionGsap scrollToTop={scrollToTop} />
     <OurProducts scrollToTop={scrollToTop} class="our-products home-product" heading={heading} title={title} subtitle={subtitle} bgvideo=""  content={content}  product={product}  video={video}  btn={btn} url={url} />
     {/* <BookDemo mainheading="Book a Demo with us Today!"  subheading="Take your business to the next level with digital twinning!" /> */}

    </>
  )
}

export default Home
