import './popup.css';
import banner from '../images/PopupProjectPlanning.webp';
import { NavLink } from 'react-router-dom';

const PopupProjectPlanning = ({scrollToTop}) => {
  return (
    <div className='popup-container'>
        <h4>Streamline Project Planning processes with virtual 3D models</h4>    
        <img src={banner} alt='' />

        <h5>What we do</h5>
        <p>Our digital twin technology revolutionizes project planning by creating virtual replicas of physical assets, processes, or systems. These digital twins mirror their real-world counterparts, offering a dynamic and interactive experience that is set to transform the way we approach construction and infrastructure projects.</p>
        <ul>
            <li>Develop accurate 3D models of project sites</li>
            <li>Integrate real-time data for up-to-date information</li>
            <li>Enable virtual simulations and scenario testing</li>
            <li>Provide tools for collaboration among stakeholders</li>
        </ul>

        <h5>Streamlined Project Planning</h5>
        <p>Improve operational decisions with real-time data and insights.</p>
        <ul>
            <li>Offer comprehensive 3D site models</li>
            <li>Enable precise project timelines</li>
            <li>Enhance collaboration among teams</li>
        </ul>

        <h5>Risk Mitigation</h5>
        <p>Identify and address potential issues early.</p>
        <ul>
            <li>Detect design flaws before construction</li>
            <li>Implement improved safety measures</li>
            <li>Reduce the risk of project delays</li>
        </ul>

        <h5>Cost Efficiency</h5>
        <p>Optimize resources and reduce project costs.</p>
        <ul>
            <li>Efficiently manage materials and resources</li>
            <li>Minimize rework and wastage</li>
            <li>Streamline project execution for cost savings</li>
        </ul>

        <h5>Improved Communication</h5>
        <p>Enhance stakeholder communication and collaboration.</p>
        <ul>
            <li>Provide clear visual representations</li>
            <li>Facilitate virtual meetings and reviews</li>
        </ul>

        <NavLink className="button" onClick={scrollToTop} to='/contact'>book a demo</NavLink>
    </div>
  )
}

export default PopupProjectPlanning
