import React from 'react';
import { NavLink } from "react-router-dom";
// import bgvideoposter from "../images/solution-banner.webp";
// import bgvideo from "../video/solutions-banner.mp4";
import './solutionsbanner.css';
const SolutionsBanner = ({scrollToTop}) => {
  return (
    <div className='solutions-banner'>
         {/* <video id="background-video" title='bg-video' playsInline poster={bgvideoposter} loop={true} muted={true} autoPlay={true} >
            <source src={bgvideo} type="video/mp4" />
            Your browser does not support the video tag.
         </video> */}
        <div className='text-box'>
              
              <h2 className='desktop'>Innovative, Accurate, and<br /> Simplified Digital Twinning <br /> Solutions</h2>
              <h3 className='desktop'>Experience the future of 3D <br /> Visualization with Repliq</h3>
          
              <h2 className='mobile'>Innovative, Accurate, and Simplified Digital Twinning Solutions</h2>
              <h3 className='mobile'>Experience the future of 3D Visualization with Repliq</h3>
          
        </div>
        <div className='d-flex align-center gap-md-4 gap-3'>
            {/* <NavLink to="/#" onClick={scrollToTop}  className="button">Book a Demo</NavLink> */}
            <NavLink to="/why-repliq" onClick={scrollToTop}  className="button">Why repliq</NavLink>
        </div>
    </div>
  )
}

export default SolutionsBanner
