import React, { useState } from 'react'
import './bookdemo.css'

import { Formik, Form, useFormik, Field } from "formik";
import * as Yup from "yup";

const initialValues = {
    fname: "",
    lname: "",
    email: "",
    phone: "",
    message: "",
}


const BookDemo = (props) => {

    const [hide, setHide] = useState(false);
    const [response, setResponse] = useState();
    // const [modalShow, setModalShow] = useState();

    const rotateicon = () => {
       const el = document.querySelector(".query-box");
       el.classList.toggle("select");
    }

    const subscribeSchema = Yup.object({
        fname: Yup.string().min(2).max(50).required("Please enter your first name").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        lname: Yup.string().min(2).max(50).required("Please enter your last name").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        email: Yup.string().email().min(2).required("Please enter your Email").matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email must be a valid email "),
        phone: Yup.string().min(10).max(14).required("Please enter your Phone Number").matches(/^[0-9\s]+$/, "Only Numbers are allowed for this field "),
        query: Yup.string().required("Please select your Query"),
        message: Yup.string().min(4).required("Please enter Message"),
     });
      

     const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
     useFormik({
         initialValues,
         validationSchema: subscribeSchema,
         onSubmit: (values, action) => {


                 const formData = new FormData();
                 formData.append("fname", values.fname);
                 formData.append("lname", values.lname);
                 formData.append("email", values.email);
                 formData.append("phone", values.phone);
                 formData.append("query", values.query);
                 formData.append("message", values.message);

                setHide(true);
            //     fetch('https://blog.preferred-square.com/wp-json/mail/getintouch', {
            //          method: 'POST',
            //          // mode: 'no-cors',
            //          // headers: {
            //          // 'Content-Type': 'application/json',
            //          // },
                     
            //          body:formData,
            //      })
            //  .then(res => res.json())
            //  .then(data => {
            //    setHide(false);
            //    console.log(data)
            //    setResponse(data.message);
            //  })
         
            //  .catch(error => {
            //      // enter your logic for when there is an error (ex. error toast)
            //      console.log(error.message);
            //  }) 
       
             console.log(
               values
             );
      
            //    setphone();       
             action.resetForm();
             setResponse(
                values.fname + " <br>" +
                values.lname + " <br>" +
                values.email + " <br>" +
                values.phone + " <br>" +
                values.query + " <br>" +
                values.message + " <br>" 
             );
            //  setResponse(data.message);
             setHide(false);
            // }
           
         },
     });
     
  return (
    <section className='book-demo'>

        <div className='container'> 
            <div className='top-box'>
                <h2 className='main-heading'>{props.mainheading}</h2>   
                <p>{props.subheading}</p>
            </div>
            <Formik>
                 {() => (
                      <Form className='book-form' onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='mb-4'>
                                <Field type="text" name="fname" placeholder='First Name' aria-labelledby="fname" value={values.fname}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                                <div className='errormsg'> {errors.fname && touched.fname ? (
                                    <p className="form-error">{errors.fname}</p>
                                ) : null}</div>
                                </div>
                            </div>      

                            <div className='col-md-6'>
                              <div className='mb-4'>
                                <Field type="text" name="lname" placeholder='Last Name' aria-labelledby="lname" value={values.lname}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                                <div className='errormsg'> {errors.fname && touched.lnameame ? (
                                    <p className="form-error">{errors.lname}</p>
                                ) : null}</div>
                                </div>
                            </div>      
                        </div> 

                          
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='mb-4'>
                                <label htmlFor="email" className=""></label>
                                <Field type="email" name="email" placeholder='Email ID' aria-labelledby="email" value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                                {/* <ErrorMessage name="email" component="div" /> */}
                                <div className='errormsg'> {errors.email && touched.email ? (
                                    <p className="form-error">{errors.email}</p>
                                ) : null}</div>
                            </div>

                            </div>

                            <div className='col-md-6'>
                                <div className='mb-4'>
                                <label htmlFor="phone" className=""></label>
                                <Field type="text" name="phone" placeholder='Phone Number' aria-labelledby="phone" value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur} />
                                {/* <ErrorMessage name="email" component="div" /> */}
                                <div className='errormsg'> {errors.phone && touched.phone ? (
                                    <p className="form-error">{errors.phone}</p>
                                ) : null}</div>
                            </div>

                            </div>
                        
                        
                        </div> 

                        <div className='row'>
                            
                            <div className='col-md-12'>
                                 <div className='mb-4 query-box'>
                                      {/* <label htmlFor="country" className="">query*</label> */}
                                      <select name="query" className="form-control" onClick={() => rotateicon()}  value={values.query}
                                          onChange={handleChange}
                                          onBlur={handleBlur}>
                                          <option value="" defaultValue className='query'>Which solutions are you interested in?</option>
                                          <option value="Digital Twinning for Spaces" className='query'>Digital Twinning for Spaces</option>
                                          <option value="Digital Twin Solutions Cities & Large Spaces" className='query'>Digital Twin Solutions Cities & Large Spaces</option>
                                      </select>
                                      <div className='errormsg'> {errors.query && touched.query ? (
                                          <p className="form-error">{errors.query}</p>
                                      ) : null}</div>
                                  </div>


                                <div className='mb-4'>
                                    {/* <label htmlFor="message" className="">Message*</label> */}
                                    <Field component="textarea" placeholder="Leave a message..." name="message" row="10" aria-labelledby="message" value={values.message}
                                        onChange={handleChange}
                                        onBlur={handleBlur} />
                                    {/* <ErrorMessage name="message" component="div" /> */}
                                    <div className='errormsg'> {errors.message && touched.message ? (
                                        <p className="form-error">{errors.message}</p>
                                    ) : null}</div>
                                </div>


                            </div>        
                        </div>

                   
                          

                          <div className='text-left'>
                              {(hide) ? (
                                  <button
                                      disabled
                                      style={{ opacity: "0.5" }}
                                      type="submit">
                                      SUBMIT
                                  </button>

                              ) : (
                                  <button type="submit">SUBMIT</button>
                              )
                              }
                          </div>

                          <div className="form-success text-center mt-5" dangerouslySetInnerHTML={{ __html: response }}></div>

                      </Form>
                  )}
            </Formik>
           
        </div> 
    </section>
  )
}

export default BookDemo
