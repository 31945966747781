import React from 'react'
import Pprivacybody from '../component/Pprivacybody'
import PpolicyBanner from '../component/PpolicyBanner'
import { Helmet } from 'react-helmet'

const PrivacyPolicy = () => {
  return (
    <>
     <Helmet>
        <title>Privacy Policy</title>
        <meta property="og:title" content="Privacy Policy" />
        <meta name='og:description' content='Privacy Policy'/>
        <meta name='keywords' content=''/>
      </Helmet>
      <PpolicyBanner />
      <Pprivacybody />
    </>
  )
}

export default PrivacyPolicy
