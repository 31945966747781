import React from 'react'
import redefine from '../images/about-redefine.webp';
import './aboutredefining.css';

const AboutRedefining = () => {
    return (
        <div className='about-redefining'>
            <div className='container'>
                <div className="top-section">
                    <h4 className='sub-heading desktop'>Redefining digital twinning for faster, simpler, <br />cost-effective modeling and enhanced visualization of products and spaces</h4>
                    <h4 className='sub-heading mobile'>Redefining digital twinning for faster, simpler, cost-effective modeling and enhanced visualization of products and spaces</h4>
                </div>

                <div className='solution'>
                    <div className='left'>
                        <img src={redefine} alt="" />
                    </div>
    
                    <div className='right'>
                        <p>At Repliq, we're at the forefront of digital twinning innovation, offering simplified and HD solutions that will reshape the industry landscape. By delivering rapid results at a fraction of the cost, we're revolutionizing the market in India, eliminating the need for traditional methods and heavy equipment.</p> 
                        <p>Our vision is to become the go-to choice for digital twinning, offering simplified solutions that cater to businesses and individuals alike, from single products to entire cityscapes.</p>
                    </div>
               
                </div>
            </div>
        </div>
    )
}

export default AboutRedefining
