import React from 'react'
import './homebanner.css'
import { NavLink } from "react-router-dom";
import bgvideoposter from "../images/home_banner.webp";
import bgvideo from "../video/home-banner.mp4";

const HomeBanner = ({scrollToTop}) => {
  return (
    <div className='header-banner'>
         <video id="background-video" title='bg-video' playsInline poster={bgvideoposter} loop={true} muted={true} autoPlay={true} >
            <source src={bgvideo} type="video/mp4" />
            {/* <source src={bgvideo} type="video/ogg" /> */}
            Your browser does not support the video tag.
         </video>
        <div className='text-box'>
              <div className="scroller">
                <h2>
                  Capture <br/>
                  Visualize   <br/>
                  Transform  
                </h2>
              </div>
              <h5>Reimagine your space with simplified<br /> and efficient Digital Twinning</h5>
          
        </div>
        <div className='d-flex align-center gap-md-4 gap-3'>
              <NavLink to="/solutions" onClick={scrollToTop}  className="button">Explore our solutions</NavLink>
              {/* <NavLink to="/#" onClick={scrollToTop}  className="button">Book a Demo</NavLink> */}
        </div>
    </div>
  )
}

export default HomeBanner
