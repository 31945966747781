import React from 'react'
import { Helmet } from 'react-helmet';
import SolutionsBanner from '../component/SolutionsBanner';
import OurProducts from '../component/OurProducts';
// import BookDemo from '../component/BookDemo';
import bgvideo from "../video/our-product-sp.mp4";
import product from '../images/our-product-solutions.webp';
import OurSolutions from '../component/OurSolutions';

const Solutions = ({scrollToTop}) => {

  const heading="Our Products";
  const title = "Twinzu - Our Flagship Digital Twinning App";
  const subtitle = "Discover digital twin technology at your fingertips";
  const content = '<ul><li>Enjoy unlimited access on both PCs and smartphones</li><li>Get support across multiple channels</li><li>Experience 3D rendering for a realistic view</li><li>Receive real-time notifications</li><li>Dive into in-depth data analytics</li><li>Seamlessly integrate with APIs</li></ul>';
  const video = "https://www.youtube.com/embed/sQ22pm-xvrE";
  const btn = "Book a Demo";
  const url = "/contact";
  

  return (
    <>
    <Helmet>
       <title>Solutions</title>
       <meta property="og:title" content="Home" />
       <meta name='og:description' content='Home'/>
       <meta name='keywords' content=''/>
    </Helmet>
      <SolutionsBanner scrollToTop={scrollToTop} />
      <OurSolutions />
      <OurProducts scrollToTop={scrollToTop} class="our-products solution-product" heading={heading}  title={title} subtitle={subtitle} bgvideo={bgvideo}  content={content}  product={product}  video={video}  btn={btn} url={url} />
      {/* <BookDemo mainheading="Want to know more about our digital twinning solutions and how they can benefit your business? "  subheading="Book a Demo with us Today!" /> */}
   </>
  )
}

export default Solutions
