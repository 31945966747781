import React, { useEffect } from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { NavLink } from "react-router-dom";
// import arrowright from '../images/arrow-right-2.svg';
// import managedservices from '../images/ManagedServices.webp';
// import managedservices2 from '../images/ManagedServices2.webp';

import borderbottom  from '../images/corevaluesborder.svg'
import borderbottomcircle  from '../images/corevaluescircle.svg'


import './corevalue.css'

import simplicityw from '../images/Simplicityw.webp'
import simplicityb from '../images/Simplicityb.webp'
import simplicitymb from '../images/simplicity-mb.webp'

import speedw from '../images/Speedw.webp'
import speedb from '../images/Speedb.webp'
import speedmb from '../images/speed-mb.webp'

import efficiencyw from '../images/Efficiencyw.webp'
import efficiencyb from '../images/Efficiencyb.webp'
import efficiencymb from '../images/efficiency-mb.webp'

import innovationw from '../images/Innovationw.webp'
import innovationb from '../images/Innovationb.webp'
import innovationmb from '../images/innovation-mb.webp'

const data = [
    {
        id:1,
        heading:'Simplicity',
        img:simplicityw,
        imghover:simplicityb,
        imgmb:simplicitymb,
    },
    {
        id:2,
        heading:'Speed',
        img:speedw,
        imghover:speedb,
        imgmb:speedmb,
    },
    {
        id:3,
        heading:'Efficiency',
        img:efficiencyw,
        imghover:efficiencyb,
        imgmb:efficiencymb,
    },
    {
        id:4,
        heading:'Innovation',
        img:innovationw,
        imghover:innovationb,
        imgmb:innovationmb,
    },
]


gsap.registerPlugin(ScrollTrigger);


const CoreValues = () => {

 
// document.body.classList.add('no-scroll');

// window.onload = () => {
//   console.log('loaded');
//   document.body.classList.remove('no-scroll');
// }
      

    useEffect(() => {
        // Your GSAP animation and ScrollTrigger setup
        let sections = gsap.utils.toArray(".main-container .panel");
        
        gsap.to(sections, {
          xPercent: -100,
        //   xPercent: -100 * (sections.length - (sections.length / 1.5)),
          ease: "none",
          scrollTrigger: {
            trigger: ".main-container",
            pin: true,
            // scrub: 1 / ((sections.length * 4) - 1),
            scrub: true,
            // snap: 1 / (sections.length - 1),
            // snap: 1 / ((sections.length * 4) - 1),
            // base vertical scrolling on how wide the container is so it feels more natural.
            // end: "+=3500",
            // end:"100vw",
            end: "+=1800",
            // markers: true,
            // end: () => "+=" + document.querySelector(".main-container").clientWidth - 1134,
          }
        });
       
        // Refresh ScrollTrigger after the component mounts
        ScrollTrigger.refresh();
    
        // Optional: Clean up on component unmount
        return () => {
          ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
      }, []);
    

    return (
    <>
     <div className='core-values desktop'>
       
      
       <div className="main-container">
        
          <div className="top-section">
                 <h2 className='main-heading'>Core Values</h2>   
           </div>

           {data.map((d, i, arr) => (
                 <section className="panel" key={d.id}>
                    <div className='container'>
                    <div className='value'>
                        <h5 className='heading'>{d.heading}</h5>    
                        <img className='img' src={d.img} alt="" />
                        <img className="hoverimg" src={d.imghover} alt="" />
                    </div>
                      {(i === data.length - 1) ? 
                        <img className='bottom-border' src={borderbottomcircle} alt='' /> :
                        <img className='bottom-border' src={borderbottom} alt='' />
                       }
                    
                    </div>
                   
                </section> 
            ))} 
       </div>
     </div>

     <div className='core-values mobile'>
       
      
       <div className="core-values-container">
        
          <div className="top-section">
                 <h2 className='main-heading'>Core Values</h2>   
           </div>

           {data.map((d, i, arr) => (
                 <section className="panel" key={d.id}>
                    <div className='container'>
                      <div className='value'>
                          <h5 className='heading'>{d.heading}</h5>    
                          <img className='img' src={d.imgmb} alt="" />
                          {/* <img className="hoverimg" src={d.imghover} alt="" /> */}
                      </div>
                    </div>
                </section> 
            ))} 
       </div>
     </div>
    </>
  )
}

export default CoreValues
