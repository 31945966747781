import React from 'react'
import './ppolicybanner.css'

const PpolicyBanner = () => {
  return (
    <div className='ppolicy-banner'>
        <div className='text-box'>
              <h2>Privacy Policy</h2>
        </div>
    </div>
  )
}

export default PpolicyBanner
