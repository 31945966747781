import React from 'react'
import './popup.css';
import banner from '../images/Popup3DVisualization.webp';
import { NavLink } from 'react-router-dom';

const Popup3DVisualization = ({scrollToTop}) => {
  return (
    <div className='popup-container'>
        <h4>Visualize your existing spaces through 3D Visuals and Models</h4>    
        <img src={banner} alt='' />

        <h5>What we do</h5>
        <p>Our digital twin technology offers an innovative 3D visualization and modeling solution, revolutionizing how companies approach design, marketing, and simulation. It provides a powerful 3D visualization and modeling tool, offering a realistic and comprehensive representation of physical spaces or systems.</p>
        <ul>
            <li>Develop precise 3D models of physical or conceptual spaces and assets</li>
            <li>Integrate real-time data for dynamic updates</li>
            <li>Enable interactive and immersive visual experiences</li>
            <li>Support various industries including architecture, construction, and manufacturing</li>
        </ul>

        <h5>Enhanced Design Accuracy</h5>
        <p>Achieve higher precision in your design projects.</p>
        <ul>
            <li>Create detailed and accurate 3D models</li>
            <li>Visualize complex structures and components</li>
            <li>Minimize design errors and revisions</li>
        </ul>

        <h5>Improved Collaboration</h5>
        <p>Facilitate better teamwork and stakeholder engagement.</p>
        <ul>
            <li>Share interactive 3D models with teams</li>
            <li>Enable real-time collaboration and feedback</li>
            <li>Ensure alignment among all project participants</li>
        </ul>

        <h5>Cost Efficiency</h5>
        <p>Reduce costs through improved planning and resource management.</p>
        <ul>
            <li>Optimize material usage and minimize waste</li>
            <li>Identify and address potential issues early</li>
            <li>Streamline project timelines and reduce delays</li>
        </ul>

        <h5>Increased Productivity</h5>
        <p>Boost productivity with advanced visualization tools.</p>
        <ul>
            <li>Accelerate design and approval processes</li>
            <li>Simplify complex project planning</li>
            <li>Enhance workflow efficiency with integrated solutions</li>
        </ul>

        <h5>Versatile Applications</h5>
        <p>Choose from various scalable applications that offer benefits across different industries and adapt to evolving business needs.</p>
        <ul>
            <li>Support architectural design and construction</li>
            <li>Enhance manufacturing and product development</li>
            <li>Improve urban planning and infrastructure projects</li>
        </ul>

        <NavLink className="button" onClick={scrollToTop} to='/contact'>  book a demo </NavLink>
    </div>
  )
}

export default Popup3DVisualization
