import React from 'react'
import brandlogo from "../images/repliq_logo.webp";
import { NavLink } from 'react-router-dom';
import './footer.css';

import facebook from '../images/facebook.svg'
import linkedin from '../images/linkedin.svg'
import twitter from '../images/twitter.svg'
import youtube from '../images/youtube.svg'

const Footer = ({scrollToTop,book_demo}) => {

    window.onload = () => {
        console.log('loaded');
        document.body.classList.remove('no-scroll');
      }

  return (
    <>
    <div className='cta'>
        <div className='container'>
            <div className='cta-box'>
                <div className=''>
                    {/* <h3 className='desktop' >Book a Demo with us Today</h3>
                    <h3 className='mobile'>Book a Demo<br /> with us Today</h3>
                    <p className='desktop'>Take your business to the next level with digital twinning</p>
                    <p className='mobile'>Take your business to <br />the next level with digital twinning</p> */}
                    <h3 className='desktop'>Take your business to<br /> the next level with digital twinning</h3>
                    <h3 className='mobile'>Take your business to the next level with digital twinning</h3>
                </div>
                <div className=''>
                <NavLink className="button" onClick={book_demo}> Book a Demo</NavLink>
                </div>
            </div>
        </div>    
    </div>
    <section className='footer'>
        <div className='container'>
            <div className='flex-box'>
                <div className='logo'>
                    <img src={brandlogo } alt='' />
                </div>

                <div className='space'></div>

                <div className='links'>
                    {/* <h2>Sitemap</h2> */}
                    <ul className='footer-links'>
                        {/* <li><NavLink onClick={scrollToTop} to='/why-repliq'>Why Repliq</NavLink></li>
                        <li><NavLink onClick={scrollToTop} to=''>Applications</NavLink></li>
                        <li><NavLink onClick={scrollToTop} to=''>About Repliq</NavLink></li> */}

                        <li><NavLink onClick={scrollToTop}  to='/solutions'>Solutions</NavLink></li>
                        <li><NavLink onClick={scrollToTop} to='/why-repliq'>Why Repliq</NavLink></li>
                    </ul>
                </div>
                <div className='links'>
                    {/* <h2 style={{opacity:"0"}}>Get Connected</h2> */}
                    <ul className='footer-links'>
                        {/* <li><NavLink onClick={scrollToTop}  to=''>Our Offering</NavLink></li>
                        <li><NavLink onClick={scrollToTop}  to='/solutions'>Solutions</NavLink></li>
                        <li><NavLink onClick={scrollToTop}  to='/contact'>Contact Us</NavLink></li> */}

                        <li><NavLink onClick={scrollToTop} to='/about-repliq'>About Repliq</NavLink></li>
                        <li><NavLink onClick={scrollToTop}  to='/contact'>Contact Us</NavLink></li>

                    </ul>
                </div>
            </div>
        </div>
        <div className='copyright'>
            <div className='container'>
                <div className='cr-flex'>
                    <div className='left'>
                        <p>@2023 Repliq. All Rights Reserved.</p>
                        <NavLink onClick={scrollToTop}  to='privacy-policy'>Privacy Policy</NavLink>
                    </div>
                    <div className='right'>
                        <ul className='social-icons'>
                            <li><div className='icon-box'><img src={facebook} alt=''/></div></li>
                            <li><div className='icon-box'><img src={linkedin} alt=''/></div></li>
                            <li><div className='icon-box'><img src={twitter} alt=''/></div></li>
                            <li><div className='icon-box'><img src={youtube} alt=''/></div></li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Footer
