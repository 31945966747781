import React, { useEffect } from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { NavLink } from "react-router-dom";
import arrowright from '../images/arrow-right-2.svg';
import managedservices from '../images/ManagedServices.webp';
import managedservices2 from '../images/ManagedServices2.webp';
import managedservices3 from '../images/ManagedServices3.webp';
import bottomborder from '../images/solutionbottomborder.svg'
import './oursolutiongsap.css';

 gsap.registerPlugin(ScrollTrigger);

const OurSolutionGsap = ({scrollToTop}) => {

// document.body.classList.add('no-scroll');

// window.onload = () => {
//   console.log('loaded');
//   document.body.classList.remove('no-scroll');
// }


    // useEffect(() => {

    
    //   let sections = gsap.utils.toArray(".panel");
      
    //   gsap.to(sections, {
    //     xPercent: -100 * (sections.length - 1),
    //     ease: "none",
    //     scrollTrigger: {
    //       trigger: ".main-container",
    //       pin: true,
    //       scrub: 1,
    //       snap: 1 / (sections.length - 1),
    //       // base vertical scrolling on how wide the container is so it feels more natural.
    //       // end: "+=3500",
    //       end: () => "+=" + document.querySelector(".main-container").offsetWidth
    //     }
    //   });
      
      
    //   // gsap.registerPlugin(ScrollTrigger);

    //   // let sections = gsap.utils.toArray(".panel");

    //   // gsap.to(sections, {
    //   //   xPercent: -100 * (sections.length - 1),
    //   //   ease: "none",
    //   //   scrollTrigger: {
    //   //     trigger: ".main-container",
    //   //     pin: true,
    //   //     scrub: 1,
    //   //     snap: 1 / (sections.length - 1),
    //   //     end: () => "+=" + document.querySelector(".main-container").offsetWidth
    //   //   }
    //   // });



    // });


    useEffect(() => {
      // Your GSAP animation and ScrollTrigger setup
      let sections = gsap.utils.toArray(".panel");
      
      gsap.to(sections, {
        xPercent: -100 * (sections.length - (sections.length / 4.5)),
        // xPercent: -100 * (sections.length - (sections.length / 4)),
        ease: "none",
        scrollTrigger: {
          trigger: ".main-container",
          pin: true,
          // scrub: 1 / ((sections.length * 4) - 1),
          scrub: true,
          // snap: 1 / (sections.length - 1),
          // snap: 1 / ((sections.length * 4) - 1),
          // base vertical scrolling on how wide the container is so it feels more natural.
          // end: "+=3500",
          end: "+=4898",
          // end: "+=5000",
          // markers: true,
          // end: () => "+=" + document.querySelector(".main-container").clientWidth - 1134,
        }
      });




// last updated

// useEffect(() => {
//   // Your GSAP animation and ScrollTrigger setup
//   let sections = gsap.utils.toArray(".panel");
  
//   gsap.to(sections, {
//     xPercent: -100 * (sections.length - (sections.length / 4)),
//     ease: "none",
//     scrollTrigger: {
//       trigger: ".main-container",
//       pin: true,
//       scrub: 2,
//       // snap: 1 / (sections.length - 1),
//       // base vertical scrolling on how wide the container is so it feels more natural.
//       // end: "+=3500",
//       end: () => "+=" + document.querySelector(".main-container").clientWidth - 1134,
//     }
//   });

// last updated



      // gsap.to('.top-container', {
      //   ease: "none",
      //   scrollTrigger: {
      //     trigger: ".main-container",
      //     // scrub: 1,
      //     scrub: true,
      //     pin: true,
      //     markers: true, 
      //     // snap: 1 / (sections.length - 1),
      //     // base vertical scrolling on how wide the container is so it feels more natural.
      //     // end: "+=3500",
      //     end: () => "+=" + document.querySelector(".main-container").clientWidth
      //   }
      // });
      
      // Refresh ScrollTrigger after the component mounts
      ScrollTrigger.refresh();
  
      // Optional: Clean up on component unmount
      return () => {
        ScrollTrigger.getAll().forEach(trigger => trigger.kill());
      };
    }, []);
  

  return (
    // <div classNameNameName='our-solution'>
    //  <div classNameNameName="top-section">
    //         <h2 classNameNameName='main-heading'>Our Solutions</h2>   
    //         <h4 classNameNameName='sub-heading'>Bespoke Next-Gen Solutions to suit your business needs</h4>
    //         <p>Explore our suite of digital twin solutions designed to innovate, elevate and reimagine your spaces and products.</p>
    //   </div>
    //     <div classNameNameName="sticky-container">
    //     <div classNameNameName='scroll-wrapper'>
    //         <section>
    //             <div classNameNameName='container'>
    //                 <div classNameNameName='solution'>
    //                     <div classNameNameName='left'>
    //                         <span>01</span>
    //                         <h2>Digital Twinning for Spaces</h2>
    //                         <h4>High-quality, 3D visualization of your space</h4>
    //                         <p>We use advanced AI and high-definition 3D visualization to create digital replicas of your spaces. We provide accurate virtual twins that enhance decision-making, optimize space utilization, and streamline operations.</p>
    //                         <NavLink to="#"> Learn more <img src={arrowright} alt="" /></NavLink>
    //                     </div>

    //                     <div classNameNameName='right'>
    //                         <img src={managedservices} alt="" />
    //                     </div>
    //                 </div>            
    //             </div>
                
    //         </section>

    //         <section>
    //             <div classNameNameName='container'>
    //                 <div classNameNameName='solution'>
    //                     <div classNameNameName='left'>
    //                         <span>01</span>
    //                         <h2>Digital Twinning for Spaces</h2>
    //                         <h4>High-quality, 3D visualization of your space</h4>
    //                         <p>We use advanced AI and high-definition 3D visualization to create digital replicas of your spaces. We provide accurate virtual twins that enhance decision-making, optimize space utilization, and streamline operations.</p>
    //                         <NavLink to="#"> Learn more <img src={arrowright} alt="" /></NavLink>
    //                     </div>

    //                     <div classNameNameName='right'>
    //                         <img src={managedservices} alt="" />
    //                     </div>
    //                 </div>            
    //             </div>
                
    //         </section>

    //         <section>
    //             <div classNameNameName='container'>
    //                 <div classNameNameName='solution'>
    //                     <div classNameNameName='left'>
    //                         <span>01</span>
    //                         <h2>Digital Twinning for Spaces</h2>
    //                         <h4>High-quality, 3D visualization of your space</h4>
    //                         <p>We use advanced AI and high-definition 3D visualization to create digital replicas of your spaces. We provide accurate virtual twins that enhance decision-making, optimize space utilization, and streamline operations.</p>
    //                         <NavLink to="#"> Learn more <img src={arrowright} alt="" /></NavLink>
    //                     </div>

    //                     <div classNameNameName='right'>
    //                         <img src={managedservices} alt="" />
    //                     </div>
    //                 </div>            
    //             </div>
                
    //         </section>
    //     </div>
    //     </div>
    // </div>
    <>
    <div className='our-solution desktop'>
       
      
      <div className="main-container">
       
         <div className="top-section">
                <h2 className='main-heading'>Our Solutions</h2>   
                <h4 className='sub-heading'>Bespoke Next-Gen Solutions to suit your business needs</h4>
                <p>Explore our suite of digital twin solutions designed to innovate, elevate and reimagine your spaces and products.</p>
          </div>
        <section className="panel">
            <div className='container'>
              <div className='solution'>
                <div className='left'>
                  <span className='no'>01</span>
                  <h2>Digital Twinning for Spaces</h2>
                  <h4>High-quality, 3D visualization of your space</h4>
                  <p>We use advanced AI and high-definition 3D visualization to create digital replicas of your spaces. We provide accurate virtual twins that enhance decision-making, optimize space utilization, and streamline operations.</p>
                  <NavLink to="/solutions" onClick={scrollToTop} > Learn more <img src={arrowright} alt="" /></NavLink>
                </div>

                <div className='right'>
                  <img src={managedservices} alt="" />
                </div>
              </div>
              <img className='bottom-border' src={bottomborder} alt=''/>
            </div>
        </section>

        
        <section className="panel">
            <div className='container'>
              <div className='solution'>
                <div className='left'>
                  <span>02</span>
                  <h2>Digital Twin Technology As a Managed Service</h2>
                  <h4>Trust the experts with your digital twinning strategy</h4>
                  <p>Our team creates and maintains virtual replicas of your spaces with customizable AI models tailored to your business needs. We provide on-site service to ensure seamless integration with existing data sources, continuous monitoring, up-to-date insights, and scalable solutions for growing data volumes.</p>
                  <NavLink to="/solutions" onClick={scrollToTop} > Learn more <img src={arrowright} alt="" /></NavLink>
                </div>

                <div className='right'>
                  <img src={managedservices2} alt="" />
                </div>
              </div>
              <img className='bottom-border' src={bottomborder} alt=''/>
            </div>
        </section>

        
        <section className="panel">
            <div className='container'>
              <div className='solution'>
                <div className='left'>
                  <span>03</span>
                  <h2>Digital Twin Solutions Cities & Large Spaces</h2>
                  <h4>Visualize urban landscapes with accurate replicas of cities and regions</h4>
                  <p>We provide advanced Digital Twinning Solutions for cities and regions using satellite-based technology. Our services include creating highly accurate virtual replicas of urban and regional environments, enabling real-time data analysis, visualization, and monitoring.</p>
                  <NavLink to="/solutions" onClick={scrollToTop} > Learn more <img src={arrowright} alt="" /></NavLink>
                </div>

                <div className='right'>
                  <img src={managedservices3} alt="" />
                </div>
              </div>
              <img className='bottom-border' src={bottomborder} alt=''/>
            </div>
        </section>

        
        {/* <section className="panel">
            <div className='container'>
              <div className='solution'>
                <div className='left'>
                  <span>04</span>
                  <h2>Digital Twinning for Spaces</h2>
                  <h4>High-quality, 3D visualization of your space</h4>
                  <p>We use advanced AI and high-definition 3D visualization to create digital replicas of your spaces. We provide accurate virtual twins that enhance decision-making, optimize space utilization, and streamline operations.</p>
                  <NavLink to="/solutions" onClick={scrollToTop} > Learn more <img src={arrowright} alt="" /></NavLink>
                </div>

                <div className='right'>
                  <img src={managedservices2} alt="" />
                </div>
              </div>
              <img className='bottom-border' src={bottomborder} alt=''/>
            </div>
        </section> */}
        
      </div>
    </div>


    <div className='our-solution mobile'>

       <div className="solutions-container">
        
          <div className="top-section">
                 <h2 className='main-heading'>Our Solutions</h2>   
                 <h4 className='sub-heading'>Bespoke Next-Gen Solutions to suit your business needs</h4>
                 <p>Explore our suite of digital twin solutions designed to innovate, elevate and reimagine your spaces and products.</p>
           </div>

               <div className='solution'>
                 <div className='left'>
                   <span className='no'>01</span>
                   <h2>Digital Twinning for Spaces</h2>
                   <h4>High-quality, 3D visualization of your space</h4>
                   <p>We use advanced AI and high-definition 3D visualization to create digital replicas of your spaces. We provide accurate virtual twins that enhance decision-making, optimize space utilization, and streamline operations.</p>
                   <NavLink to="/solutions" onClick={scrollToTop} > Learn more <img src={arrowright} alt="" /></NavLink>
                 </div>
 
                 <div className='right'>
                   <img src={managedservices} alt="" />
                 </div>
               </div>
         
               <div className='solution'>
                 <div className='left'>
                   <span>02</span>
                   <h2>Digital Twin Technology As a Managed Service</h2>
                   <h4>Trust the experts with your digital twinning strategy</h4>
                   <p>Our team creates and maintains virtual replicas of your spaces with customizable AI models tailored to your business needs. We provide on-site service to ensure seamless integration with existing data sources, continuous monitoring, up-to-date insights, and scalable solutions for growing data volumes.</p>
                   <NavLink to="/solutions" onClick={scrollToTop} > Learn more <img src={arrowright} alt="" /></NavLink>
                 </div>
 
                 <div className='right'>
                   <img src={managedservices2} alt="" />
                 </div>
               </div>

               <div className='solution'>
                 <div className='left'>
                   <span>03</span>
                   <h2>Digital Twin Solutions Cities & Large Spaces</h2>
                   <h4>Visualize/Envision urban landscapes with accurate replicas of cities and regions</h4>
                   <p>We provide advanced Digital Twinning Solutions for cities and regions using satellite-based technology. Our services include creating highly accurate virtual replicas of urban and regional environments, enabling real-time data analysis, visualization, and monitoring.</p>
                   <NavLink to="/solutions" onClick={scrollToTop} > Learn more <img src={arrowright} alt="" /></NavLink>
                 </div>
    
                 <div className='right'>
                   <img src={managedservices3} alt="" />
                 </div>
               </div>
           
            
       </div>
     </div>
    </>
  )
}

export default OurSolutionGsap
