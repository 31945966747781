import React from 'react';
import { NavLink } from "react-router-dom";
// import bgvideoposter from "../images/whyrepliq-banner.webp";
// import bgvideo from "../video/whyrepliq-banner.mp4";
import './whyrepliqbanner.css';

const WhyReplicBanner = ({scrollToTop}) => {
  return (
    <div className='whyrepliq-banner'>
         {/* <video id="background-video" title='bg-video' playsInline poster={bgvideoposter} loop={true} muted={true} autoPlay={true} >
            <source src={bgvideo} type="video/mp4" />
            <source src={bgvideo} type="video/ogg" />
            Your browser does not support the video tag.
         </video> */}
        
        <div className='text-box'>
          <h2 className='desktop'>Step into the Future with<br /> Repliq's Digital Twin <br />Expertise</h2>
          <h2 className='mobile'>Step into the Future with Repliq's Digital Twin Expertise</h2>
        </div>
        
        <div className='d-flex align-center gap-md-4 gap-3'>
          {/* <NavLink to="/#" onClick={scrollToTop}  className="button">Book a Demo</NavLink> */}
          <NavLink to="/solutions" onClick={scrollToTop}  className="button">Explore our solutions</NavLink>
        </div>
    </div>
  )
}

export default WhyReplicBanner