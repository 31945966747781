import React from 'react'
import './whychoose.css';

const WhyChoose = () => {
  return (
    <div className='whychoose'>
        <div className='container'>
            <div className="top-section">
                <h4 className='sub-heading'>Why Choose Repliq?</h4>
                <p>At Repliq, we're dedicated to providing exceptional digital twinning solutions tailored to your needs. With our innovative technology and expert team, we're ready to help you visualize and reimagine your spaces and products like never before.</p>
            </div>
        </div>
    </div>
  )
}

export default WhyChoose
