import React from 'react'
import { Helmet } from 'react-helmet';
import ContactBanner from '../component/ContactBanner';
import BookDemo from '../component/BookDemo';
import WhyChoose from '../component/WhyChoose';

const Contact = () => {
  return (
    <>
    <Helmet>
     <title>Contact</title>
     <meta property="og:title" content="Home" />
     <meta name='og:description' content='Home'/>
     <meta name='keywords' content=''/>
   </Helmet>

       <div>
        <ContactBanner />
        <WhyChoose />
        <BookDemo mainheading="Have questions or inquiries? We're here to help"  subheading="Fill out the form below to get in touch with us. Our team will respond to your inquiry as soon as possible." />
       </div>
   </>
  )
}

export default Contact
