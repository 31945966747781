import './popup.css';
import banner from '../images/PopupSpacePlanning.webp';
import { NavLink } from 'react-router-dom';

const PopupSpacePlanning = ({scrollToTop}) => {
  return (
    <div className='popup-container'>
        <h4>Bring Interior Design concepts to life with real-time 3D visualization</h4>    
        <img src={banner} alt='' />

        <h5>What we do</h5>
        <p>Our digital twinning solution creates a virtual replica of a room or building, allowing designers to experiment with layouts and decor realistically. </p>
        <ul>
            <li>Develop accurate 3D models of interior spaces</li>
            <li>Integrate real-time data for dynamic updates</li>
            <li>Enable interactive visualization and customization</li>
            <li>Support collaboration among designers, clients, and stakeholders</li>
        </ul>

        <h5>Enhanced Design Visualization</h5>
        <p>Achieve better design accuracy with detailed 3D models.</p>
        <ul>
            <li>Visualize interior layouts and furnishings</li>
            <li>Experiment with different design concepts</li>
            <li>Minimize design errors and revisions</li>
        </ul>

        <h5>Improved Client Collaboration</h5>
        <p>Facilitate better communication and engagement with clients.</p>
        <ul>
            <li>Share interactive 3D models for feedback</li>
            <li>Enable real-time collaboration and adjustments</li>
            <li>Ensure clients are aligned with design concepts</li>
        </ul>

        <h5>Customizable Designs</h5>
        <p>Adapt designs to meet specific client needs and preferences.</p>
        <ul>
            <li>Offer personalized design options</li>
            <li>Easily modify and update designs</li>
            <li>Ensure client satisfaction with tailored solutions</li>
        </ul>

        <h5>Cost Efficiency</h5>
        <p>Reduce costs through improved planning and resource management.</p>
        <ul>
            <li>Optimize material usage and minimize waste</li>
            <li>Identify potential issues before implementation</li>
            <li>Streamline project timelines and reduce delays</li>
        </ul>

        <h5>Increased Productivity</h5>
        <p>Boost productivity with advanced design tools.</p>
        <ul>
            <li>Accelerate design and approval processes</li>
            <li>Simplify project planning and execution</li>
            <li>Enhance workflow efficiency with integrated solutions</li>
        </ul>

        <NavLink className="button" onClick={scrollToTop} to='/contact'>book a demo </NavLink>
    </div>
  )
}

export default PopupSpacePlanning
