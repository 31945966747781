import React from 'react'
import './popup.css';
import banner from '../images/PopupFacilityManagement.webp';
import { NavLink } from 'react-router-dom';

const PopupFacilityManagement = ({scrollToTop}) => {
  return (
    <div className='popup'>
        <div className='popup-container'>
            <h4>Optimize Facility Operations with real-time Digital Twin Technology</h4>    
            <img src={banner} alt='' />

            <h5>What we do</h5>
            <p>Our digital twin solution is a game-changer. It provides a comprehensive view of facility layouts, assets, and resources on a single platform, empowering you with real-time monitoring and management capabilities for your buildings and infrastructure.</p>
            <ul>
                <li>Develop a virtual 3D model of your facility</li>
                <li>Integrate real-time data from sensors and IoT devices</li>
                <li>Provide predictive maintenance alerts and insights</li>
                <li>Enable remote monitoring and control</li>
            </ul>

            <h5>Enhanced Decision-Making</h5>
            <p>Improve operational decisions with real-time data and insights.</p>
            <ul>
                <li>Access real-time facility data</li>
                <li>Utilize predictive analytics for maintenance</li>
                <li>Make informed decisions based on accurate simulations</li>
            </ul>

            <h5>Operational Efficiency</h5>
            <p>Allows easy access to manage critical information and prevent downtime.</p>
            <ul>
                <li>Allows automated monitoring and control</li>
                <li>Detect issues early on and take proactive measures</li>
                <li>Lower maintenance costs</li>
            </ul>

            <h5>Cost Savings</h5>
            <p>Reduces risks and costs of physical site visits by providing digital asset and layout representations to project collaborators.</p>
            <ul>
                <li>Optimize your resource usage</li>
                <li>Decrease energy consumption</li>
                <li>Minimize maintenance expenses</li>
            </ul>
        
            <h5>Improved Safety</h5>
            <p>Enhance safety protocols and reduce risks.</p>
            <ul>
                <li>Monitor safety conditions in real-time</li>
                <li>Implement predictive safety measures</li>
                <li>Reduce the likelihood of accidents</li>
            </ul>

            <NavLink className="button" onClick={scrollToTop} to='/contact'>book a demo</NavLink>
        </div>
    </div>
  )
}

export default PopupFacilityManagement
