import React from 'react'
import './oursolutions.css'

import space from '../images/spaces.webp'
import expertise from '../images/expertise.webp'
import cities from '../images/cities.webp'

import icon from '../images/plus-icon.svg'

const OurSolutions = () => {
  return (
    <div className='our-solutions'> 
        <div className='container'>
            <div className='solution-wrapper'>
                <div className='our-solution'>
                       <div className='img-box'>
                            <div className='img'><img src={space} alt='' /></div>
                            <div className='sr'><span>01</span></div>
                            <div className='img-text'>
                                <h2>Digital twinning for spaces</h2>
                                <h4>Visualize the potential of your space with a high-quality, 3D replica</h4>
                                <p>With our Digital Twin technlogy, all you need is an HD camera like a GoPro to visualize your spaces in stunning detail. No more heavy equipment or long processing times - just quick, high-quality results.</p>
                            </div>
                        </div> 
                       <div className='text-box'>
                            <div className='benefits'>
                            <h4 className='sub-heading'>Benefits</h4>
                                <h5> What You Get</h5>
                                <img className="mobile plus-icon" src={icon} alt='' />
                                <div className='hidden-text'>
                                    <ul>
                                        <li><b>Enhanced Visualization:</b>Create detailed, interactive 3D models of spaces.</li>
                                        <li><b>Real-Time Insights: </b>Get access to real-time data analysis only with Polytron AI.</li>
                                        <li><b>Operational Efficiency: </b>Optimize space utilization and streamline operations.</li>
                                        <li><b>Remote Accessibility: </b>Access IoT and Industry 4.0 systems from any device, anywhere.</li>
                                        <li><b>Improved Decision-Making: </b>Enhance strategic planning with advanced insights and analytics.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='features'>
                            <h4 className='sub-heading'>Features</h4>
                                <h5>What Sets Us Apart</h5>
                                <img className="mobile plus-icon" src={icon} alt='' />
                                <div className='hidden-text'>
                                    <ul>
                                        <li><b>Custom AI Models: </b>Tailored to specific business requirements.</li>
                                        <li><b>Seamless Integration: </b>Connect with existing data sources effortlessly.</li>
                                        <li><b>Data Visualization: </b>Use of maps and 3D models for better understanding.</li>
                                        <li><b>Predictive Analytics: </b>Proactively predict issues and failures by analyzing historical and real-time data.</li>
                                        <li><b>Virtual Testing:  </b>Reduce time and cost associated with physical testing of assets.</li>
                                    </ul>
                                </div>
                            </div>
                        </div> 
                </div>

                <div className='our-solution reverse'>
                       <div className='img-box'>
                            <div className='img'><img src={expertise} alt='' /></div>
                            <div className='sr'><span>02</span></div>
                            <div className='img-text'>
                                <h2>Digital twinning as professional expertise</h2>
                                <h4>Simplified Digital Twinning Solutions with our in-house experts</h4>
                                <p>Get our Digital Twin Technology as a managed service, and our expert team will handle everything. Just tell us what you need, and we'll take care of the rest.</p>
                            </div>
                        </div> 
                       <div className='text-box'>
                            <div className='benefits'>
                            <h4 className='sub-heading'>Benefits</h4>
                                <h5>What You Get</h5>
                                <img className="mobile plus-icon" src={icon} alt='' />
                                <div className='hidden-text'>
                                    <ul>
                                        <li><b>Enhanced Efficiency:</b>Streamline operations and optimize resources with real-time monitoring and predictive analytics managed by our experts.</li>
                                        <li><b>Improved Decision Making: </b>Benefit from our experts' insights derived from data-driven models, enabling informed decisions and reduced downtime.</li>
                                        <li><b>Hassle-Free: </b>Enjoy on-site support for all your digital twin processes and issues.</li>
                                        <li><b>Cost-Effectiveness: </b>Proactively identify inefficiencies and maintenance needs, reducing overall operational costs.</li>
                                        <li><b>Scalability: </b>Easily scale to accommodate growing business needs and evolving technologies.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='features'>
                            <h4 className='sub-heading'>Features</h4>
                                <h5>What Sets Us Apart</h5>
                                <img className="mobile plus-icon" src={icon} alt='' />
                                <div className='hidden-text'>
                                    <ul>
                                        <li><b>Real-time Monitoring: </b>Monitor assets and processes in real-time, enabling quick response to changing conditions.</li>
                                        <li><b>Predictive Analytics: </b>Use historical data and AI to predict future performance and maintenance needs.</li>
                                        <li><b>Custom AI Models: </b>Create virtual models of physical assets to simulate scenarios and optimize performance.</li>
                                        <li><b>Remote Management: </b>Manage and control assets remotely, reducing the need for physical presence.</li>
                                        <li><b>User-friendly Interface: </b>Intuitive interface for easy navigation and monitoring of assets and processes.</li>
                                        <li><b>24/7 Support: </b>Access to technical support and assistance round-the-clock to address any issues or concerns.</li>
                                    </ul>
                                </div>
                            </div>
                        </div> 
                </div>

                <div className='our-solution'>
                       <div className='img-box'>
                            <div className='img'><img src={cities} alt='' /></div>
                            <div className='sr'><span>03</span></div>
                            <div className='img-text'>
                                <h2>Digital twinning for 3D mapping: cities & large spaces</h2>
                                <h4>Effortlessly visualize and navigate larger spaces such as cities and towns</h4>
                                <p>Harness the power of our Digital Twin Technology for unparalleled geo-mapping accuracy.</p>
                            </div>
                        </div> 
                       <div className='text-box'>
                            <div className='benefits'>
                            <h4 className='sub-heading'>Benefits</h4>
                                <h5>What You Get</h5>
                                <img className="mobile plus-icon" src={icon} alt='' />
                                <div className='hidden-text'>
                                    <ul>
                                        <li><b>Accurate Models:</b>Create detailed and accurate digital models of geographical areas for analysis and planning.</li>
                                        <li><b>Improved Decision Making: </b> Make informed decisions based on real-time data and simulations.</li>
                                        <li><b>Resource Managementy: </b>Optimize resource allocation by understanding geographical conditions and requirements.</li>
                                        <li><b>Risk Assessment: </b>Identify and mitigate risks by simulating various scenarios and their potential impact on geographical areas.</li>
                                        <li><b>Cost Savings: </b>Save costs by reducing the need for physical surveys and by optimizing resource usage based on digital models.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='features'>
                            <h4 className='sub-heading'>Features</h4>
                                <h5>What Sets Us Apart</h5>
                                <img className="mobile plus-icon" src={icon} alt='' />
                                <div className='hidden-text'>
                                    <ul>
                                        <li><b>HD Mapping: </b>Create detailed and high-resolution digital twins of cities, towns and other large spaces for accurate representation.</li>
                                        <li><b>Real-time Integration: </b>Integrate real-time data sources, such as sensors and satellite imagery, for up-to-date information.</li>
                                        <li><b>Dynamic Models: </b>Simulate various scenarios and their effects on geographical areas to understand potential outcomes.</li>
                                        <li><b>Customization: </b>Tailor the digital replica of your space to specific geographical features and requirements for better analysis and planning.</li>
                                        <li><b>Accessibility:  </b>Access the virtual replica of your space from anywhere, at any time, for quick decision-making and collaboration.</li>
                                    </ul>
                                </div>
                            </div>
                        </div> 
                </div>

            </div>
        </div>
    </div>
  )
}

export default OurSolutions
