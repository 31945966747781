import React from 'react'
import whyrepliq1 from '../images/whyrepliq1.webp';
import whyrepliq2 from '../images/whyrepliq2.webp';
import './wr_about.css';

const WRAbout = () => {
  return (
    <div className='wr_about'>
       <div className='container'>
                <div className='imgbox-section'>
                    <div className='img-box'>
                        <img src={whyrepliq1} alt="" />
                    </div>
    
                    <div className='text-box'>
                        {/* <h3>About Repliq</h3> */}
                        <h3>Unlock The Power Of Cutting-edge Digital Twin Technology To Transform How Spaces Are Visualized And Experienced.</h3>
                        <p>Repliq is the leading platform for space and product visualization, offering cutting-edge digital twinning technology. We create high-definition virtual replicas, enabling users to design and optimize spaces and products with unmatched precision.</p>
                    </div>
               
                </div>

                <div className='imgbox-section flex-reverse'>
                    <div className='img-box'>
                        <img src={whyrepliq2} alt="" />
                    </div>
    
                    <div className='text-box'>
                        <h3>Our Commitment To Excellence</h3>
                        <p>What sets Repliq apart is our commitment to innovation and customer satisfaction. We continuously enhance our technology to meet evolving user needs, ensuring a cutting-edge solution that transforms space planning and gives you a competitive edge.</p>
                    </div>
               
                </div>

                {/* <h4 className='desktop'>Unlock the power of cutting-edge digital twin technology to <br /> transform how spaces are visualized and experienced.</h4>
                <h4 className='mobile'>Unlock the power of cutting-edge digital twin technology to transform how spaces are visualized and experienced.</h4> */}
            </div>
    </div>
  )
}

export default WRAbout
