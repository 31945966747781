import './popup.css';
import banner from '../images/PopupVirtualTours.webp';
import { NavLink } from 'react-router-dom';

const PopupVirtualTours = ({scrollToTop}) => {
  return (
    <div className='popup-container'>
        <h4>Create Immersive Virtual Experiences through real-time data integration</h4>    
        <img src={banner} alt='' />

        <h5>What we do</h5>
        <p>Our digital twin technology revolutionizes virtual tours by creating interactive, accurate, and personalized experiences through real-time data integration and replacing manual intervention.</p>
        <ul>
            <li>Generate detailed 3D replicas of physical spaces</li>
            <li>Enable real-time navigation and interaction</li>
            <li>Customize tours for different industries like real estate, tourism, etc</li>
        </ul>

        <h5>Enhanced User Engagement</h5>
        <p>Attract potential buyers and tourists through an interactive 3D experience.</p>
        <ul>
            <li>Provide realistic 3D environments</li>
            <li>Enable interactive features for deeper exploration</li>
            <li>Offer personalized tour options</li>
        </ul>

        <h5>Cost-Effective Marketing</h5>
        <p>Reduce marketing expenses with digital solutions.</p>
        <ul>
            <li>Eliminate the need for physical visits</li>
            <li>Reach a global audience effortlessly</li>
            <li>Provide 24/7/unlimited access to virtual tours</li>
        </ul>

        <h5>Improved Decision-Making</h5>
        <p>Facilitate decision-making with comprehensive virtual representations.</p>
        <ul>
            <li>Offer detailed visualizations of properties or sites</li>
            <li>Allow easy comparison of different options</li>
            <li>Provide access to additional information through embedded multimedia</li>
        </ul>

        <h5>Increased Accessibility</h5>
        <p>Make locations accessible to a wider audience.</p>
        <ul>
            <li>Offer access to remote or restricted areas</li>
            <li>Cater to individuals with mobility issues</li>
            <li>Automate tour scheduling and management</li>
        </ul>

        <NavLink className="button" onClick={scrollToTop} to='/contact'>book a demo  </NavLink>
    </div>
  )
}

export default PopupVirtualTours
