
import { useState } from 'react';
import { NavLink } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';

import PopupFacilityManagement from './PopupFacilityManagement'
import Popup3DVisualization from './Popup3DVisualization'
import PopupConsumerBehavior from './PopupConsumerBehavior'
import PopupInteriorDesigning from './PopupInteriorDesigning'
import PopupProductCataloging from './PopupProductCataloging'
import PopupProjectPlanning from './PopupProjectPlanning'
import PopupVirtualTours from './PopupVirtualTours'
import PopupSafety from './PopupSafety'
import PopupSpacePlanning from './PopupSpacePlanning'


import './wrapplications.css';

import facility from '../images/Facility Management.webp';
import Virtual from '../images/Virtual Tours.webp';
import prpplanning from '../images/Project Planning.webp';
import dVisualization from '../images/3D Visualization & Modelling.webp';
import spaceplanning from '../images/Space Planning.webp';
import interiordesigning from '../images/Interior Designing.webp';
import contactbanner from '../images/Consumer Behavior Analysis.webp';
import Safety from '../images/Safety and Security.webp';
import productcatalog from '../images/Product Cataloging.webp';

import arrowright from '../images/arrow-right.svg';



const WrApplications = ({scrollToTop}) => {

    const [show, setShow] = useState(false);
    const [popup , setpopup] = useState('consumerbehavior');

    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const handlePopup = (type) => {
        setpopup(type);
        setShow(true);
    }


    const data = [
        {
            id: 1,
            image: facility,
            image_mb: facility,
            heading: 'Facility Management',
            type:"facility",
            link: '#'
        },
        {
            id: 2,
            image: Virtual,
            image_mb: Virtual,
            heading: 'Virtual Tours',
            type:"virtualtours",
            link: '#'
        },
        {
            id: 3,
            image: prpplanning,
            image_mb: prpplanning,
            heading: 'Project Planning',
            type:"projectplanning",
            link: '#'
        },
        {
            id: 4,
            image: dVisualization,
            image_mb: dVisualization,
            heading:'3D Visualization & Modelling',
            type:"visualization",
            link: '#'
        },
        {
            id: 5,
            image: spaceplanning,
            image_mb: spaceplanning,
            heading: 'Space Planning',
            type:"spaceplanning",
            link: '#'
        },
        {
            id: 6,
            image: interiordesigning,
            image_mb: interiordesigning,
            heading: 'Interior Designing',
            type:"interiorDesigning",
            link: '#'
        },
        {
            id: 7,
            image: contactbanner,
            image_mb: contactbanner,
            heading: 'Consumer Behavior Analysis',
            type:"consumerbehavior",
            link: '#'
        },
        {
            id: 8,
            image: Safety,
            image_mb: Safety,
            heading: 'Safety and Security',
            type:"safety",
            link: '#'
        },
        {
            id: 9,
            image: productcatalog,
            image_mb: productcatalog,
            heading: 'Product Cataloging',
            type:"productcataloging",
            link: '#'
        }
    ]

  return (
    <>

    <Modal className='modal-applications' show={show} size='lg' centered={true}  onHide={handleClose} >
        <Modal.Header closeButton></Modal.Header>
        {popup === 'facility' && <PopupFacilityManagement scrollToTop={scrollToTop} />}
        {popup === 'visualization' && <Popup3DVisualization scrollToTop={scrollToTop}  />}
        {popup === 'consumerbehavior' && <PopupConsumerBehavior scrollToTop={scrollToTop}  />}
        {popup === 'interiorDesigning' && <PopupInteriorDesigning scrollToTop={scrollToTop}  />}
        {popup === 'productcataloging' && <PopupProductCataloging scrollToTop={scrollToTop}  />}      
        {popup === 'projectplanning' && <PopupProjectPlanning scrollToTop={scrollToTop}  />}
        {popup === 'safety' && <PopupSafety scrollToTop={scrollToTop}  />}
        {popup === 'virtualtours' && <PopupVirtualTours scrollToTop={scrollToTop}  />}
        {popup === 'spaceplanning' && <PopupSpacePlanning scrollToTop={scrollToTop}  />}
      
    </Modal>

    

    <div className='wp-applications'>
        <div className='container'>
            <div className='top-section'>
                 <h2 className='main-heading'>Tailored, Future-Forward Solutions For Different Industries</h2>   
                 {/* <h3>Applications</h3>
                 <h4 className='sub-heading'>How we can make a difference across various industries with our next-gen technology!</h4> */}
            </div>


                
          
            <div className='hover-boxes'>

                   {data.map((d, i) => (
                    <div className='hover-box' key={i}>
                        <img className='hover-img desktop' src={d.image} alt=""  />
                        <img className='hover-img mobile' src={d.image_mb} alt=""  />
                        <h4 className='title'>{d.heading}</h4>
                        <div className='hover-text'>
                            {/* <h4>Make digital twinning easier with our intuitive, seamless platform.</h4> */}
                            {/* <NavLink to={d.heading} onClick={handlePopup(d.type)}> Learn more <img src={arrowright} alt="" /></NavLink> */}
                            <NavLink to='#' onClick={()=>{handlePopup(d.type);}}> Learn More <img src={arrowright} alt="" /></NavLink>
                        </div>
                    </div>
                  ))}

                {/* <div className='hover-box'>
                    <img className='hover-img desktop' src={simple} alt=""  />
                    <img className='hover-img mobile' src={simplemb} alt=""  />
                    <h4 className='title'>Simple</h4>
                    <div className='hover-text'>
                        <h4>Make digital twinning easier with our intuitive, seamless platform.</h4>
                        <NavLink to="#"> Learn more <img src={arrowright} alt="" /></NavLink>
                    </div>
                </div>

                <div className='hover-box'>
                    <img className='hover-img desktop' src={fast} alt=""  />
                    <img className='hover-img mobile' src={fastmb} alt=""  />
                    <h4 className='title'>Fast</h4>
                    <div className='hover-text'>
                        <h4>Create digital twins quickly with our scalable solutions, enabling faster decision-making and project implementation.</h4>
                        <NavLink to="#"> Learn more <img src={arrowright} alt="" /></NavLink>
                    </div>
                </div>

                <div className='hover-box'>
                    <img className='hover-img desktop' src={efficient} alt=""  />
                    <img className='hover-img mobile' src={efficientmb} alt=""  />
                    <h4 className='title'>Efficient</h4>
                    <div className='hover-text'>
                        <h4>Maximize productivity through our solutions that allow streamlined operations, reduced costs, and increased efficiency.</h4>
                        <NavLink to="#"> Learn more <img src={arrowright} alt="" /></NavLink>
                    </div>
                </div>

                <div className='hover-box'>
                    <img className='hover-img desktop' src={innovative} alt=""  />
                    <img className='hover-img mobile' src={innovativemb} alt=""  />
                    <h4 className='title'>Innovative</h4>
                    <div className='hover-text'>
                        <h4>Create digital replicas of your space with advanced AI and high-definition 3D visualization.</h4>
                        <NavLink to="#"> Learn more <img src={arrowright} alt="" /></NavLink>
                    </div>
                </div> */}
            </div>

            <div className='text-center my-5 py-5'>
                {/* <NavLink className="button" onClick={scrollToTop} to="/contact">Book a Demo </NavLink> */}
            </div>
        </div>
    </div>
    </>

  )
}

export default WrApplications
