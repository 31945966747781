import './popup.css';
import banner from '../images/PopupSafety.webp';
import { NavLink } from 'react-router-dom';

const PopupSafety = ({scrollToTop}) => {
  return (
    <div className='popup-container'>
        <h4>Unlock consumer insights with future-proof digital twinning solutions</h4>    
        <img src={banner} alt='' />

        <h5>What we do</h5>
        <p>Our digital twinning solution creates virtual models of consumer environments, enabling businesses to gather and analyze data in real-time.</p>
        <ul>
            <li>Develop 3D models of retail spaces and customer interaction zones</li>
            <li>Integrate real-time data from sensors, cameras, and IoT devices</li>
            <li>Enable in-depth analysis of consumer movement and  behavior patterns</li>
            <li>Provide tools for visualizing and interpreting consumer data</li>
        </ul>

        <h5>Enhanced Consumer Insights</h5>
        <p>Gain a deeper understanding of consumer behavior.</p>
        <ul>
            <li>Analyze consumer movement and interaction patterns</li>
            <li>Identify high-traffic areas and optimize layouts</li>
            <li>Understand consumer preferences and behaviors</li>
        </ul>

        <h5>Data-Driven Decision Making</h5>
        <p>Make informed decisions based on real-time consumer data.</p>
        <ul>
            <li>Visualize consumer behavior with detailed 3D models</li>
            <li>Use data analytics to drive marketing and sales strategies</li>
            <li>Optimize product placement and store layouts</li>
        </ul>

        <h5>Improved Customer Experience</h5>
        <p>Enhance shopping experiences by understanding consumer needs.</p>
        <ul>
            <li>Personalize shopping experiences based on behavior analysis</li>
            <li>Improve store layouts for better customer flow</li>
            <li>Increase customer satisfaction and loyalty</li>
        </ul>

        <h5>Increased Revenue</h5>
        <p>Boost sales and profitability through targeted strategies.</p>
        <ul>
            <li>Implement effective marketing campaigns based on consumer data</li>
            <li>Maximize sales by optimizing product placement</li>
            <li>Increase revenue through improved customer engagement</li>
        </ul>

        <NavLink className="button" onClick={scrollToTop} to='/contact'> book a demo  </NavLink>
    </div>
  )
}

export default PopupSafety
