import React from 'react'
import { Helmet } from 'react-helmet';
import WhyReplicBanner from '../component/WhyReplicBanner';
import WRAbout from '../component/WRAbout';
// import BookDemo from '../component/BookDemo';
import WrApplications from '../component/WrApplications';

const WhyRepliq = ({scrollToTop}) => {
  return (
    <>
     <Helmet>
        <title>Why Repliq</title>
        <meta property="og:title" content="Why Repliq" />
        <meta name='og:description' content='Why Repliq'/>
        <meta name='keywords' content=''/>
     </Helmet>
     <WhyReplicBanner scrollToTop={scrollToTop} />
     <WRAbout />
     <WrApplications scrollToTop={scrollToTop} />
     {/* <BookDemo mainheading="Embrace the future of space planning with digital twinning!"  subheading="Book a Demo with us Today!" /> */}

    </>
  )
}

export default WhyRepliq
