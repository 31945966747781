import React from 'react'
import './popup.css';
import banner from '../images/PopupInteriorDesigning.webp';
import { NavLink } from 'react-router-dom';

const PopupInteriorDesigning = ({scrollToTop}) => {
  return (
    <div className='popup-container'>
        <h4>Enhance Space Planning efficiency with interactive 3D digital replicas</h4>    
        <img src={banner} alt='' />

        <h5>What we do</h5>
        <p>Our cutting-edge technology provides accurate and interactive 3D models for effective space planning.</p>
        <ul>
            <li>Develop precise 3D models of existing and proposed spaces</li>
            <li>Integrate real-time data for dynamic updates and adjustments</li>
            <li>Enable interactive visualization and scenario testing</li>
            <li>Support collaboration among designers, planners, and stakeholders</li>
        </ul>

        <h5>Improved Space Utilization</h5>
        <p>Maximize the efficiency of space planning with detailed virtual 3D models of physical spaces.</p>
        <ul>
            <li>Visualize layouts and furniture arrangements</li>
            <li>Experiment with different configurations</li>
            <li>Optimize space for functionality and aesthetics</li>
        </ul>

        <h5>Enhanced Productivity</h5>
        <p>Increase productivity with state-of-the-art planning tools.</p>
        <ul>
            <li>Speed up design and approval processes</li>
            <li>Streamline project planning and execution</li>
            <li>Improve workflow efficiency with integrated solutions</li>
        </ul>

        <h5>Flexible Solutions</h5>
        <p>Tailor space plans to meet unique needs and preferences.</p>
        <ul>
            <li>Provide personalized space planning options</li>
            <li>Easily modify and update plans</li>
            <li>Ensure client satisfaction with customized solutions</li>
        </ul>
        
        <NavLink className="button" onClick={scrollToTop} to='/contact'> book a demo </NavLink>
    </div>
  )
}

export default PopupInteriorDesigning
